import { createContext, useContext, useCallback, useState } from "react";
import { EventNames, useWindowEvent, AppEmitter } from "../app-events";

const FrameCommunicationContext = createContext();

export const FrameCommunicationProvider = ({ children }) => {
  const [loadNotified, setLoadNotified] = useState(false);

  const sendTeamsUserRequest = (teamsUserRequest) => {
    window.parent.postMessage(
      {
        type: EventNames.onPlayerTeamsUserRequest,
        request: teamsUserRequest,
      },
      "*"
    );
  };

  const sendExitEvent = () => {
    window.parent.postMessage(
      {
        type: EventNames.onPlayerMeetingEnded,
        request: "",
      },
      "*"
    );
  };
  const sendCallStartedEvent = () => {
    window.parent.postMessage(
      {
        type: EventNames.onPlayerMeetingOrganizerJoined,
        request: "",
      },
      "*"
    );
  };

  const frameLoaded = () => {
    if (!loadNotified) {
      setLoadNotified(true); // safety measure
      window.parent.postMessage(
        {
          type: EventNames.onPlayerLoaded,
          request: {},
        },
        "*"
      );
    }
  };

  const handleExternalMessage = useCallback((event) => {
    if (event.data.type) {
      switch (event.data.type) {
        case "onPlayerTeamsUserResponse":
          AppEmitter.emit(
            EventNames.onPlayerTeamsUserResponse,
            event.data.request
          );
          break;
        default:
          break;
      }
    }
  }, []);

  // listen for external postMessage events
  useWindowEvent(EventNames.onExternalMessage, handleExternalMessage);

  return (
    <FrameCommunicationContext.Provider
      value={{
        frameLoaded,
        sendTeamsUserRequest,
        sendExitEvent,
        sendCallStartedEvent,
      }}
    >
      {children}
    </FrameCommunicationContext.Provider>
  );
};

export const useFrameCommunication = () => {
  const context = useContext(FrameCommunicationContext);

  if (!context) {
    throw new Error(
      "useFrameCommunication must be within FrameCommunicationProvider"
    );
  }

  return {
    frameLoaded: context.frameLoaded,
    sendTeamsUserRequest: context.sendTeamsUserRequest,
    sendExitEvent: context.sendExitEvent,
    sendCallStartedEvent: context.sendCallStartedEvent,
  };
};
