import React from "react";

import "../css/App.css";
import { ZoomMtg } from "@zoomus/websdk";
import { MeetingParams, IsMeetingEnded } from "../util/SearchParams";
import { useFrameCommunication } from "../providers/FrameCommunication";

ZoomMtg.setZoomJSLib("https://source.zoom.us/2.10.1/lib", "/av");

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load("en-US");
ZoomMtg.i18n.reload("en-US");

const ZoomFrame = () => {
  const {sendCallStartedEvent, sendExitEvent} = useFrameCommunication();

  if (IsMeetingEnded) {
    console.log("Meeting Ended, captured leave")
    sendExitEvent();
    return <></>;
  }

  if (MeetingParams.meetingNumber) {
    document.getElementById("zmmtg-root").style.display = "block";

    ZoomMtg.init({
      leaveUrl: "zoom-player.qualifico.com/?leave=1",
      disablePreview: true,
      success: (success) => {
        console.debug(success);

        ZoomMtg.join({
          signature: MeetingParams.signature,
          sdkKey: process.env.REACT_APP_ZOOM_MEETING_SDK_KEY,
          meetingNumber: MeetingParams.meetingNumber,
          passWord: MeetingParams.password,
          userName: MeetingParams.userName,
          userEmail: MeetingParams.userEmail,
          tk: "",
          zak: "",
          success: (success) => {
            console.debug(success);
            sendCallStartedEvent();

            ZoomMtg.inMeetingServiceListener('onUserLeave', function (data) {
              sendExitEvent();
              ZoomMtg.endMeeting({})
            });
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  } else {
    return (<div>qualifico zoom player</div>);
  }

  return (
    <div className="App">
      <main></main>
    </div>
  );
};

export default ZoomFrame;
