import React from "react";
//import ZoomComponent from "./components/ZoomComponent";
import ZoomFrame from "./components/ZoomFrame";
import { FrameCommunicationProvider } from "./providers/FrameCommunication";

const App = () => {
  
  return (
    <FrameCommunicationProvider>
      <ZoomFrame/>
    </FrameCommunicationProvider>
  );
};

export default App;
