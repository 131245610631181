import { Base64 } from "js-base64";
const searchParams = new URLSearchParams(window.location.search);

const getMeetingProps = () => {
  if (!searchParams.get("mp")) {
    return {};
  }
  return JSON.parse(Base64.decode(searchParams.get("mp")));
};

export const MeetingParams = {
  meetingNumber: searchParams.get("mn"),
  password: getMeetingProps().password,
  userName: getMeetingProps().name,
  userEmail: getMeetingProps().email,
  signature: searchParams.get("s"),
};

export const IsMeetingEnded = searchParams.get("leave")
